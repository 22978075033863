import { ProductOption } from "../../redux/services/productSelection/ProductSelection.types"
import { Mortgage } from "../../types/retain/Mortgages.types"

/* This consolidates mortgages that have identical product options.
 * If all the product options of a mortgage match another, the mortgages will be moved inside
 * the `consolidatedMortgages` of the first mortgage */
export function consolidateMortgages(mortgages: Mortgage[]) {
  const hashed = mortgages.map((mortgage, index) => ({
    index,
    mortgage,
    optionsHash: mortgage.selectedProductOptions.reduce<string>(
      (hash, option) => `${hash}-${hashOption(option)}`,
      ""
    ),
  }))

  const consolidated = hashed.reduce<
    Record<string, { index: number; mortgage: Mortgage; optionsHash: string }>
  >((mortgages, meta) => {
    const key = meta.optionsHash || `${meta.index}`
    if (!mortgages[key]) {
      mortgages[key] = {
        ...meta,
        mortgage: { ...meta.mortgage, consolidatedMortgages: [] },
      }
    } else {
      mortgages[key].mortgage.consolidatedMortgages.push(meta.mortgage)
    }
    return mortgages
  }, {})

  return Object.values(consolidated)
    .sort((a, b) => a.index - b.index)
    .map((m) => m.mortgage)
}

function hashOption(option: ProductOption): string {
  const keys: (keyof ProductOption)[] = [
    "scheme",
    "productCode",
    "fees",
    "feePaymentType",
    "monthlyPreferentialPayment",
    "preferentialRate",
    "aprc",
    "monthlyFollowOnPayment",
    "followOnRate",
    "ltv",
  ]
  return keys.reduce<string>((hash, key) => `${hash}_${option[key]}`, "")
}
